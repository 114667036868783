.navigation-left {
  height: calc(100vh - 80px);
  width: 250px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  border-right: 1px solid rgba(21, 21, 21, 0.1);
  color: #151515;
  display: flex;
  flex-direction: column;
  margin: 40px 0;

  @media (max-width: 640px) {
    left: -75%;
    // height: 100vh;
    margin: 0;
    padding: 20px 0;
    width: 75%;
    min-height: 100vh;
    min-height: -webkit-fill-available;

    &.open {
      display: flex;
      z-index: 120;
      left: 0;
      transition: 1s all;
    }
  }

  .logo {
    padding: 0 20px 40px 20px;
    text-align: center;
    border-bottom: 1px solid rgba(21, 21, 21, 0.1);

    a {
      text-decoration: none;
      color: rgba(21, 21, 21, 1);
    }

    img {
      width: 120px;
    }

    @media (max-width: 640px) {
      padding: 10px 20px 30px 20px;
    }
  }

  .nav-container {
    padding: 20px 0;

    .nav-title {
      font-size: 20px;
      margin: 0;
    }

    .nav-item {
      padding: 20px 40px;
    }

    .nav-sub-list {
      padding-top: 20px;
    }

    .nav-sub-item {
      padding: 10px 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      text-decoration: none;
      color: rgba(21, 21, 21, 0.8);

      &.active,
      &:active {
        color: rgba(21, 21, 21, 1);

        .line {
          background-color: rgba(21, 21, 21, 1);
        }
      }

      &:hover {
        color: rgba(21, 21, 21, 1);

        .line {
          animation: animateWidth ease 1s;
          background-color: rgba(21, 21, 21, 0.8);
        }
      }

      .line {
        width: 100%;
        height: 2px;
        background-color: rgba(21, 21, 21, 0.3);
        display: flex;
      }

      .line-wrapper {
        width: 30%;
        height: 2px;
        margin-right: 20px;
      }
    }
  }

  @keyframes animateWidth {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  .social {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    a {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    svg {
      width: 40px;
      height: 40px;
      margin: 0 10px;
    }
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
