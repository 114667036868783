.segmenter {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  grid-row: 1 / 3;
  grid-column: 1 / 5;
}

.segmenter__image {
  width: 100%;
  display: block;
}

.segmenter__background,
.segmenter__pieces,
.segmenter__piece-wrap,
.segmenter__piece-parallax,
.segmenter__piece {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.segmenter__piece-parallax {
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
}

.segmenter__pieces {
  -webkit-perspective: 400px;
  perspective: 400px;
}

.segmenter__background,
.segmenter__piece {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.segmenter__shadow {
  position: absolute;
  opacity: 0;
  box-shadow: 2px 20px 35px rgba(0, 0, 0, 0.9);
}

.trigger-headline {
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  z-index: 1;
}

.trigger-headline span {
  -webkit-transition: opacity 2s, -webkit-transform 2s;
  transition: opacity 2s, transform 2s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
  color: #fff;
  display: block;
}

.hero {
  display: grid;
  grid-template-columns: 10% 40% 40% 10%;
  grid-template-rows: 100vh;
  height: 100vh;
  // filter: grayscale(100%);
}

.hero-title {
  color: #fff;
  font-size: 4rem;
  line-height: 100px;
  font-weight: 300;
  letter-spacing: 10px;
  text-transform: uppercase;
  padding-bottom: 50px;
  text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);

  @media (min-width: 500px) {
    font-size: 6rem;
    line-height: 130px;
  }
}

.hero-sub {
  line-height: 1.5;
  font-size: 30px;
  font-weight: 300;
  border-left: 10px solid;
  padding-left: 40px;
  text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);

  @media (min-width: 500px) {
    font-size: 40px;
  }
}

.scroll-wrapper {
  grid-row-start: 2;
  grid-column: 2 / 4;
  z-index: 1;
  justify-self: center;
  align-self: center;
  position: relative;
  z-index: 11;

  .scroll-down {
    cursor: pointer;
    font-size: 0.8rem;

    &:hover {
      color: #ccc;
    }
  }

  .scroll-line {
    position: absolute;
    height: 60px;
    width: 2px;
    top: -80px;
    left: 50%;
    background: #fff;
    animation: blink 3s ease-in-out infinite;
  }
}

@keyframes blink {
  50% {
    opacity: 0.2;
    top: -85px;
  }
}
