main {
  display: grid;
  grid-template-columns: 250px auto;

  @media (max-width: 640px) {
    grid-template-columns: auto;
  }
}

.content {
  padding: 40px;
  grid-column: 2;

  @media (max-width: 640px) {
    margin-top: 80px;
    padding: 20px;
  }
}

// NEW
.content-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 640px) {
    margin-top: 20px;
  }
}

.line {
  width: 60px;
  height: 2px;
  background-color: rgba(21, 21, 21, 0.3);
  margin-right: 20px;
}

.no-scroll {
  overflow: hidden;
  max-height: calc(100vh - 80px);
}
