.navigation-top {
  height: 80px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  border-bottom: 1px solid rgba(21, 21, 21, 0.1);
  color: #151515;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  padding: 0 20px;

  @media (min-width: 640px) {
    display: none;
  }
}

.hamburger-menu {
  cursor: pointer;
}
