.intro {
  text-align: center;
}

.intro-text {
  font-size: 36px;
  line-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container {
  display: flex;
}

.item {
  border: 1px solid rgba(21, 21, 21, 0.1);
  flex-grow: 1;
  margin: 40px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.intro-container {
  position: relative;
  padding: 40px;
  transform: translate3d(0, 0, 0);
  display: flex;
  width: 80%;
  margin: 60px auto;

  &:hover {
    cursor: pointer;

    .plus-image {
      transform: rotate3d(0, 0, 1, 90deg);
    }
  }

  .plus-image {
    position: absolute;
    z-index: 5;
    opacity: 0.1;
    width: 30px;
    height: 30px;
    transition: all 0.5s;
  }

  .plus-image._1 {
    left: -18px;
    top: -18px;
    right: auto;
    bottom: auto;
  }

  .plus-image._2 {
    left: auto;
    top: -18px;
    right: -18px;
    bottom: auto;
  }

  .plus-image._3 {
    left: -18px;
    top: auto;
    right: auto;
    bottom: -13px;
  }

  .plus-image._4 {
    left: auto;
    top: auto;
    right: -18px;
    bottom: -13px;
  }
}

.line {
  width: 100%;
  height: 2px;
  background-color: rgba(21, 21, 21, 0.3);
  display: flex;
}

.line-wrapper {
  width: 100px;
  height: 2px;
  margin-top: 80px;
}

.image {
  width: 100%;
}
