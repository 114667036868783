.photo-detail-container {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 80px calc(100vw - 160px) 80px;
  grid-template-rows: 80px calc(100vh - 80px);
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 640px) {
    grid-template-columns: 100vw;
    grid-template-rows: 80px calc(100vh - 200px) 120px;
    z-index: 110;
  }
}

.photo-detail-title-container {
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
  user-select: none;

  @media (max-width: 640px) {
    grid-row: 1;
    grid-column: 1;
    padding: 20px;
  }
}

.photo-detail-image-wrapper {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;

  @media (max-width: 640px) {
    grid-row: 2;
    grid-column: 1;
    padding: 20px;
  }

  img {
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
    user-select: none;
  }
}

.photo-detail-title {
  display: flex;
  align-items: center;

  .line {
    width: 60px;
    height: 2px;
    background-color: rgba(21, 21, 21, 0.3);
    margin-right: 20px;
  }
}

.photo-detail-close {
  cursor: pointer;
  grid-column: 3;
  grid-row: 1;
  display: flex;
  align-items: center;
  user-select: none;

  @media (max-width: 640px) {
    grid-row: 1;
    grid-column: 1;
    padding: 20px;
    justify-self: flex-end;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.photo-detail-prev {
  grid-column: 1;
  grid-row: 2 / 3;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  transition: all ease 1s;
  cursor: pointer;
  height: 80px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 640px) {
    grid-row: 3;
    grid-column: 1;
    width: 50%;
    justify-content: flex-start;
    margin-right: 50%;
    padding-left: 20px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.photo-detail-next {
  grid-column: 3;
  grid-row: 2 / 3;
  display: flex;
  align-self: center;
  transition: all ease 1s;
  cursor: pointer;
  height: 80px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 640px) {
    grid-row: 3;
    grid-column: 1;
    width: 50%;
    justify-content: flex-end;
    margin-left: 50%;
    padding-right: 20px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
