@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: -webkit-fill-available;
}

body {
  font-family: "Oswald", sans-serif;
  color: #000;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

a:hover,
a:focus {
  color: rgba(21, 21, 21, 0.8);
  cursor: pointer;
}

.hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}

.fade-in {
  animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
