.gallery {
  columns: 450px 3;
  column-gap: 40px;
}

.image-container {
  position: relative;
  padding: 20px;
  margin: 0 -20px;
  transform-style: flat;
  -webkit-transform: -webkit-translateY (0);
  -webkit-transform: translateY(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  transform: translate3d(0, 0, 0);
  -webkit-column-break-inside: avoid;

  &:hover {
    cursor: pointer;

    .plus-image {
      transform: rotate3d(0, 0, 1, 90deg);
    }

    .overlay-line-vertical,
    .overlay-line-horizontal,
    .overlay-text-left-wrapper,
    .overlay-text-left,
    .overlay-text-bottom {
      animation: fadeIn ease 0.5s;
      opacity: 1;
    }

    .image {
      // transform: scale(1.1);
      filter: grayscale(0%);
    }
  }

  .overlay-line-vertical {
    position: absolute;
    left: 60px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: hsla(0, 0%, 100%, 0.3);
    opacity: 0;
  }

  .overlay-line-horizontal {
    position: absolute;
    left: 0;
    bottom: 60px;
    width: 100%;
    height: 1px;
    background-color: hsla(0, 0%, 100%, 0.3);
    opacity: 0;
  }

  .overlay-text-left-wrapper {
    position: absolute;
    left: 15px;
    top: 300px;
    opacity: 0;
    transform: rotate(-90deg);
    transform-origin: top left;
    width: 300px;

    .overlay-text-left {
      color: #fff;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-align: right;
      height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 35px;
    }
  }

  .overlay-text-bottom {
    position: absolute;
    right: 35px;
    bottom: 35px;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: right;
    opacity: 0;
  }

  .plus-image {
    position: absolute;
    z-index: 5;
    opacity: 0.1;
    width: 30px;
    height: 30px;
    transition: all 0.5s;
  }

  .plus-image._1 {
    left: 0;
    top: 0;
    right: auto;
    bottom: auto;
  }

  .plus-image._2 {
    left: auto;
    top: 0;
    right: 0;
    bottom: auto;
  }

  .plus-image._3 {
    left: 0;
    top: auto;
    right: auto;
    bottom: 0;
  }

  .plus-image._4 {
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
  }
}
.image-wrapper {
  overflow: hidden;
}

.image {
  transition: all 1s;
  width: 100%;
  object-fit: cover;
  filter: grayscale(100%);
}
